@import "App.scss";

.puzzle_type_logo {
    max-width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 2%;
    margin-bottom: 2%;
    position: relative;
}

.header-logo {
    max-width: 50%;
    min-width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5%;
    position: relative;
}

.navbar {
    background-image: url(./images/pattern2.png);
    width: 100% !important;
}