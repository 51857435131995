/*!
 * These are all the files we'd import with
 * @import "~bootstrap/scss/bootstrap";
 */

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "bootstrap-variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/input-group";
// @import "~bootstrap/scss/custom-forms";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/print";
@import url('https://fonts.googleapis.com/css2?family=Istok+Web:wght@700&family=Montserrat:wght@500&display=swap');

// .navbar {
//     background-image: url(./images/pattern2.png);
//     width: 100% !important;
// }

.navbarlogo {
    margin: 2%;
    max-height: 60px;
}

body {
    background-color: #f8f8f8;
    color: #001011;
    font-family: sans-serif;
    height: 100vh;
}

/* Define minimum font size */
h2  {
    font-size: 22px;

/* Fluid typography formula */
@media (min-width: 640px) and (max-width: 1439px) {
    font-size: calc(22px + (30 - 22) * (100vw - 640px) / (1439 - 640));
}

/* Define max font size */
@media (min-width: 1440px) {
    font-size: 30px;
}

}

.content {
    text-align: center;
    justify-content: center;
}

.content.landing {
    margin-top: 10%;
}

.content.lead{
    margin-top: 6%;
}

.adventure-title {
    color: #637126;
    font-family: 'Istok Web', sans-serif;
    margin-top: 5%;
    text-align: center;
    @include font-size(2rem);
}


.responsive-subtitle {
    @include font-size(1rem);
}

.logo {
    width: 70%;
    z-index: 1;
    position: relative;
    top: 40px;
    text-align: center;
    margin-left: 15%;
    margin-bottom: 5%;

}

.btn-primary {
    background-color: #E78E2A;
    border-color: white;
    color: white;
}

.checkpoint {
    box-shadow: #001011;
}
.question {
    color: #637126;
    font-family: 'Istok Web', sans-serif;
    text-align: center;
}
.question_text {
    color: #001011;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.tiny {
    font-size: .8em;
    color: #001011;
}

.link {
    color: #001011;
    text-decoration: underline;
}

.introtext {
    text-align: center;
    margin-top: 2em;
}

$fa-font-path: "../fonts/";
// $fa-css-prefix: '';

@import "./fontawesome-5/light";
@import "./fontawesome-5/_icons";

i.fal, i.fab, i.fad {
    font-style: normal !important;
}

.checkpoint-hunt i {
    // color: $orange;
    // @extend .fal;
    // @extend .fa-map-marked-alt;
    // margin-right: 10px;
    content: url(./images/hunt_logo.png);
    max-width: 100%;
}

.checkpoint-solve i {
    // color: $orange;
    // @extend .fal;
    // @extend .fa-puzzle-piece;
    // margin-right: 10px;
    content: url(./images/solve_logo.png);
    max-width: 100%;
}

.checkpoint-play i {
    // color: $green;
    // @extend .fal;
    // @extend .fa-play-circle;
    // margin-right: 10px;
    content: url(./images/play_logo.png);
    max-width: 100%;
}

.greyout {
    opacity: 0.5;
}

.tiles {
    max-width: 33%;
    min-width: 33%;
    text-align: center;
    display: flex;
    flex-direction: row;
}
.puzzletype {
    margin: 5px !important;

}

.puzzle_type_logo {
    max-width: 50%;
    text-align: center;
    position: relative;
}

.card-header {
    color: #637126;
    font-family: 'Istok Web', sans-serif;
}

.question_tiles {
    margin: 9px;
}

.progress_bar {
    // border: 1px solid rgb(88, 88, 88); 
    border-radius: 4px; 
    height: 30px;
}

.points_bar {
    border: 1px solid rgb(88, 88, 88); 
    border-radius: 4px; 
    height: 30px;
}

.form-control:focus {
    border-color:  #E78E2A;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
  }

 .centered-text {
     text-align: center;
 }

 .leaderboard-button {
     margin-top: 3%;
 }
 .medal {
     max-width: 100%;
 }

 .table-borderless {
    text-align: center;
 }

 .into-container {
    height: 80vh;
    padding-bottom: 30px;
    // height: 100vh;
    width: 100%;
    margin-bottom: 60px;
    
 }
 
 .intro-page {
     width: 100%;
     height: 100%;

 }

.slide-image {
    max-width: 100%;
    min-width: 100%;
    min-height: 70%;
    max-height: 70%;
    text-align: center;
    position: relative;
    margin-bottom: 5%;
}

.slide-image.final {
    margin-bottom: 30px;
}

picture {
        max-width: 100%;
        min-width: 100%;
        min-height: 70%;
        max-height: 70%;
        text-align: center;
        position: relative;
    }

/* Define minimum font size */
.caption {
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
    color: white;

/* Fluid typography formula */
@media (min-width: 640px) and (max-width: 1439px) {
    font-size: calc(16px + (23 - 16) * (100vw - 640px) / (1439 - 640));
}

/* Define max font size */
@media (min-width: 1440px) {
    font-size: 23px;
}

}

.pirates {
    color: #D81785;
}